.PostsWidget-module_posts-widget_1v2w7__Main {
  background-color: var(--bg-block);
  border-radius: 10px;
  padding: 10px;
}

.PostsWidget-module_posts-widget--with-spacing_aS4nQ__Main {
  margin: 20px 0;
}

.PostsWidget-module_posts-widget__header_8JBnE__Main {
  color: var(--text-primary);
}

.PostsWidget-module_posts-widget__posts_DnDIr__Main {
  flex-direction: column;
  gap: 15px;
  padding: 20px 0;
  display: flex;
}

.PostsWidget-module_post_hZg-W__Main:not(:last-child) {
  border-bottom: 1px solid var(--line-line);
  padding-bottom: 15px;
}

.PostsWidget-module_post__interactive-count_Fvfme__Main {
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  display: flex;
}

.PostsWidget-module_post__interactive-count--img_5ydhn__Main {
  border-radius: 40px;
}

.PostsWidget-module_post__interactive-count__img-wrapper_EqLSw__Main {
  display: flex;
}

.PostsWidget-module_post__interactive-count--ugc_shV1k__Main {
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  display: flex;
}

.PostsWidget-module_post__header_G-FBK__Main {
  gap: 10px;
  display: flex;
}

.PostsWidget-module_post__time_mqu-t__Main {
  color: var(--text-secondary);
  padding-right: 5px;
}

.PostsWidget-module_post__time_mqu-t__Main:first-child {
  position: relative;
}

.PostsWidget-module_post__time_mqu-t__Main:first-child:after {
  content: "";
  background-color: var(--icons-gray);
  border-radius: 50%;
  width: 3px;
  height: 3px;
  position: absolute;
  top: 40%;
  right: -6%;
}

.PostsWidget-module_post__plus-icon_Upl2G__Main {
  fill: var(--icons-primary);
}

.PostsWidget-module_post__fire-icon_xHv0e__Main {
  fill: var(--icons-yellow);
}

.PostsWidget-module_post__author-link_LJwZJ__Main {
  color: var(--text-link-active);
}

.PostsWidget-module_post__title_3nURU__Main, .PostsWidget-module_post__likes-group_8tsyZ__Main {
  color: var(--text-primary);
}

.PostsWidget-module_post__comments-group_FBvKg__Main, .PostsWidget-module_post__likes-group_8tsyZ__Main {
  vertical-align: bottom;
  color: var(--text-primary);
  align-items: center;
  gap: 5px;
  display: inline-flex;
}

